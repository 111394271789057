<template>
    <psi-dialog
        @close="$emit('close')"
        :title="$t('applications.signature')"
        icon="mdi-draw"
    >
        <v-toolbar dense>
            <v-toolbar-title>{{
                $t("applications.draw-signature")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small @click.prevent="clear">{{
                $t("applications.clear")
            }}</v-btn>
        </v-toolbar>
        <div style="width: 100%; height: 180px">
            <vue-signature
                :style="borderStyle"
                ref="signature"
                :sigOption="option"
            ></vue-signature>
        </div>
        <v-alert dense type="error" outlined :value="error" class="my-2">{{
            $t("applications.signature-required")
        }}</v-alert>
        <template v-slot:actions>
            <v-btn small color="accent" @click="save">{{
                $t("applications.save")
            }}</v-btn>
        </template>
    </psi-dialog>
</template>
<script>
import VueSignature from "vue-signature";
export default {
    name: "applicant-sign",
    components: {
        VueSignature,
    },
    props: {},
    data() {
        return {
            error: false,
            errorMessage: "",
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
        };
    },
    computed: {
        borderStyle() {
            return this.error
                ? "border:solid 1px #D3F2F"
                : "border:solid 1px #555555";
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        clear() {
            if (Object.keys(this.$refs).includes("signature")) {
                this.$refs.signature.clear();
            }
        },
        save() {
            if (this.$refs.signature.isEmpty()) {
                this.error = true;
            } else {
                this.$emit("save", this.$refs.signature.save("image/jpeg"));
            }
        },
    },
};
</script>

<style scoped>
</style>