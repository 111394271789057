var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: { title: _vm.$t("applications.signature"), icon: "mdi-draw" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "accent" },
                  on: { click: _vm.save }
                },
                [_vm._v(_vm._s(_vm.$t("applications.save")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dense: "" } },
        [
          _c("v-toolbar-title", [
            _vm._v(_vm._s(_vm.$t("applications.draw-signature")))
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { small: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.clear($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("applications.clear")))]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { width: "100%", height: "180px" } },
        [
          _c("vue-signature", {
            ref: "signature",
            style: _vm.borderStyle,
            attrs: { sigOption: _vm.option }
          })
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "my-2",
          attrs: { dense: "", type: "error", outlined: "", value: _vm.error }
        },
        [_vm._v(_vm._s(_vm.$t("applications.signature-required")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }